@import "styles/all";

.container {
  padding-inline: vw(16px);
  display: flex;
  flex-direction: column;
}

.headerTitle {
  color: $dark;
  @include font(24px, 500);
  line-height: 24px;
  margin-block: 32px;
}

.infoCol {
  display: flex;
  flex-direction: column;
  .infoColtitle {
    @include font(18px, 500);
    line-height: 18px;
  }
  .infoRow {
    display: flex;
    gap: 4px;

    padding-block: 20px;
    border-bottom: 1px solid $lightGrey;
    .tilte {
      @include font(14, 400);
      opacity: 0.8;
    }
    .infoinfo {
      @include font(14, 500);
      opacity: 0.8;
    }

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border: none;
    }
  }
}

.divider {
  margin-block: 32px;
}

.locationContainer {
  position: relative;
  opacity: 1;
  .locationBtn {
    position: absolute;
    top: 16px;
    inset-inline-end: 0px;
  }
  .btn {
    background-color: unset !important;
    border: none;
    padding: 6px;
    max-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
}

.logoutBtn {
  margin-top: vh(48px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light !important;
  border: none;
  color: $danger !important;
}
