@import "styles/all";

.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  height: 100svh;
}

.formSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  .formContainer {
    width: 100%;
    padding-inline: vw(16px);
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-inline: auto;
  }
}
