@import "styles/all";

.navbar {
  background-color: $light;
  height: $navbar-height;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  z-index: 1100;
  top: 0;
  left: 0;
}

.logoContainer {
  width: 94px;
  height: 100%;
  display: flex;
  .logo {
    object-fit: cover;
    width: 100%;
  }
}

.menuBtn {
  background-color: unset !important;
  border: none;
  padding: 0 !important;
}

.drawer :global {
  .ant-drawer-content-wrapper {
    width: vw(265px) !important;
  }
  .ant-drawer-mask {
    background: rgba(41, 45, 50, 0.64);
  }
  .ant-drawer-body {
    padding-top: $navbar-height;
    padding-inline: 0;
  }
}

.header {
  border-top: 1px solid $lightGrey;
  border-bottom: 1px solid $lightGrey;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  .title {
    @include font(18px, 500);
    line-height: 18px;
    color: $lightDark;
    opacity: 0.8;
    margin-bottom: 2px;
  }
  .secondaryTitle {
    margin-top: 10px;
    @include font(14px, 400);
    line-height: 14px;
    color: $lightDark;
    opacity: 0.8;
  }
}

.navLinks {
  border-bottom: 1px solid $lightGrey;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;

  .navigationListItem {
    border-radius: 14px;
    padding: 17px 20px;
    text-decoration: none;
  }

  .navigationListItemActive {
    background-color: $lightPrimary;
  }

  .navLinkTitle {
    @include font(14px, 500);
    line-height: 14px;
    color: $dark;
  }
}

.logoutContainer {
  padding: 24px 16px;
  .logout {
    padding: 24px 16px;
    width: 100%;
    border: none;
    background-color: unset !important;
    color: $danger !important;
  }
}
