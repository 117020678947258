$mobile-design-viewport: 390px;
$mobile-design-height: 844px;
$navbar-height: 58px;

/* Colors */
$bg: #f2f2f4;
$primary: #1e4280;
$lightPrimary: #1e428016;
$secondary: #fdebea;
$info: #121212a3;
$dark: #222425;
$lightDark: #121212;
$light: #ffffff;
$success: #1a9946;
$lightSuccess: #1a99461a;
$warning: #fea100;
$lightWarning: #fea1001a;
$grey: #494949;
$lightGrey: #dadada;
$danger: #dc3545;
$fail: #fea100;
$lightGreen: #1a9946;
$modalBackDrop: #12121280;
$lightDangerBackground: #e3332d0a;
$lightDangerBorder: #e3332d80;
$lightGreyBackground: #fcfcfc;
$secondaryDarkGrey: #a0a0a0;
