@import "styles/all";

.formSection,
.logoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  @include font(24px, 500);
  line-height: 24px;
  color: $dark;
}

.secondaryTitle {
  @include font(14px, 400);
  line-height: 20px;
  margin-top: 24px;
  margin-bottom: 32px;
  color: $lightDark;
  opacity: 0.6;
}

.form {
  .submitBtn {
    justify-content: center;
    width: 100%;
    margin-top: 125px;
  }
}
