@import "styles/all";

.customBtn {
  @include font(16px, 600);

  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;

  height: 56px;
  padding: 16px 20px;

  border-radius: 12px;

  &:hover {
    filter: brightness(1.1);
  }
}
