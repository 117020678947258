@import "styles/all";

.container {
  padding-inline: vw(16px);
  display: flex;
  flex-direction: column;
}

.headerTitle {
  color: $dark;
  @include font(24px, 500);
  line-height: 24px;
  margin-block: 32px;
}
