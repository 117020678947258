@import "styles/all";

.modal :global {
  height: 80%;

  border: none;
  .ant-modal-content {
    margin-block: 15vh;
    padding: 0;
  }
}

.modalBox {
  overflow: hidden;
  background-color: $light;
  border-radius: 16px;
  width: 100%;
}

.companyLogoAndTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  border-bottom: 1px solid $lightGrey;

  .logoContainer {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .titlesContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      color: $dark;
      opacity: 0.8;
      @include font(20px, 500);
      line-height: 20px;
    }
    .secondaryTitle {
      color: $dark;
      opacity: 0.64;
      @include font(14px, 500);
      line-height: 14px;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 20px;
}

.header {
  border-bottom: 1px solid $lightGrey;
}

.headerTitle {
  display: flex;
  align-items: center;
  width: 100%;
}

.footer {
  border-top: 1px solid $lightGrey;
  padding: 16px 20px;
  .markBtn {
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 35px;

  .successIcontContainer {
    width: 104px;
    height: 104px;
  }

  .title {
    @include font(16px, 600);
    line-height: normal;
    color: $dark;
  }

  .secondaryTitle {
    @include font(14px, 400);
    line-height: 20px;
    text-align: center;
    color: $dark;
    opacity: 0.64;
  }
}
