@import "styles/all";

.logoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;

  .logoHolder {
    width: 222px;
    height: 77px;
  }
}

.title {
  @include font(24px, 500);
  line-height: 24px;
  color: $dark;
}

.secondaryTitle {
  @include font(14px, 400);
  line-height: 14px;
  opacity: 0.6;
  color: $lightDark;
  margin-top: 14px;
}

.form {
  margin-top: 32px;
  .submitBtn {
    justify-content: center;
    width: 100%;
    margin-top: 48px;
  }
  :global {
    .ant-form-item {
      margin-bottom: 10px !important;
    }
  }
  .forgotPassword {
    display: block;
    @include font(16px, 500);
    line-height: 16px;
    margin-top: 24px;
    color: $primary;
  }
}
