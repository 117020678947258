@import "styles/all";

.container {
  .inputContent {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  .label {
    @include font(14px, 500);
    line-height: 22px;
    opacity: 0.6;
    color: $lightDark;
    margin-bottom: 6px;
    display: block;
  }

  .input {
    :global {
      @include font(16px, 600);

      height: 48px !important;
      padding: 12px 16px !important;

      font-weight: 600;
      color: #121212;

      background: $light;
      border-radius: 12px !important;
    }
  }
}
