@import "styles/all";

.container {
  background-color: $light;
  border-radius: 12px;
  position: relative;
  margin-bottom: 14px;
}

.locationAndPhoneBtns {
  position: absolute;
  top: 16px;
  inset-inline-end: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .btn {
    background-color: $bg !important;
    border: none;
    padding: 6px;
    max-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    &:hover {
      background-color: transparent !important;
    }
  }
}

.visitNumHeader {
  display: flex;
  gap: 6px;
  padding: 20px 16px;
  border-bottom: 1px solid $lightGrey;
  :first-child,
  :last-child {
    @include font(18px, 500);
    line-height: 18px;
  }
  :first-child {
    opacity: 0.64;
  }
}

.companyLogoAndTitle {
  padding: 16px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;

  .logoContainer {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    color: $dark;
    opacity: 0.8;
    @include font(20px, 500);
    line-height: 20px;
  }
}

.visitInfo {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .infoRow {
    display: flex;
    gap: 6px;
    * {
      @include font(14px, 500);
      line-height: 14px;
      color: $dark;
    }
    :first-child {
      opacity: 0.64;
    }
  }
}

.divider {
  margin-top: 0;
}

.markBtnContainer {
  padding-inline: 16px;
  padding-bottom: 16px;
  .markBtn {
    background-color: unset !important;
    color: $primary !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
  }
}
